import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import LayoutOne from '../../layouts/LayoutOne';
import Breadcrumb from '../../wrappers/breadcrumb/Breadcrumb';
import { useTranslation } from 'react-i18next';
import useLocaleConfigSelectors from '../../helpers/useLocaleConfigSelectors.js';
import { Helmet } from 'react-helmet';

const NotFound = ({ reason }) => {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { getSiteName: siteName, getCartoon404: cartoon404 } = useLocaleConfigSelectors();

	// Get appropriate error message based on reason
	// Only use specific messages if reason is provided
	const getErrorMessage = () => {
		if (!reason) {
			return t('Sorry, the page was not found');
		}

		switch (reason) {
			case 'rewriting-failed':
				return t('The URL could not be resolved');
			case 'product-fetch-failed':
				return t('The requested product could not be found');
			case 'category-fetch-failed':
				return t('The requested category could not be found');
			case 'brand-fetch-failed':
				return t('The requested brand could not be found');
			case 'content-fetch-failed':
				return t('The requested content could not be found');
			case 'content-type-not-found':
				return t('The requested content type is not supported');
			case 'route-not-found':
			default:
				return t('Sorry, the page was not found');
		}
	};

	const rainRef = useRef(null);
	useEffect(() => {
		const rainContainer = rainRef.current;
		if (!rainContainer) return;

		const background = [
			"linear-gradient(transparent, lightgray)",
			"linear-gradient(transparent, silver)",
			"linear-gradient(transparent, gray)",
			"linear-gradient(transparent, darkgray)",
			"linear-gradient(transparent, dimgray)"
		];

		const amount = 40;
		for (let i = 0; i < amount; i++) {
			const drop = document.createElement("i");
			drop.style.width = Math.random() * 5 + "px";
			drop.style.left = Math.floor(Math.random() * window.innerWidth) + "px";
			drop.style.animationDelay = Math.random() * -30 + "s";
			drop.style.animationDuration = Math.random() * 4 + 2 + "s";
			drop.style.background = background[Math.floor(Math.random() * background.length)];
			drop.style.opacity = Math.random() * 0.4 + 0.2;
			drop.style.height = "120px";
			drop.style.position = "absolute";
			drop.style.borderRadius = "0 0 999px 999px";
			drop.style.animationName = "animate-raindrops";
			drop.style.animationTimingFunction = "linear";
			drop.style.animationIterationCount = "infinite";

			rainContainer.appendChild(drop);
		}
	}, []);

	return (
		<Fragment>
			<Helmet>
				<title>{t('Not found')} | {siteName} </title>
				<meta
					name="description"
					content={t('Not found') + ' | ' + siteName}
				/>
			</Helmet>
			<BreadcrumbsItem to={process.env.PUBLIC_URL + '/'}>{t('Homepage')}</BreadcrumbsItem>
			<BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
				{t('Not found')}
			</BreadcrumbsItem>
			<LayoutOne>
				<Breadcrumb />
				<div className="rain-container" ref={rainRef}></div>
				<div className="pt-40 pb-100">
					<div className="container">
						<div className="row align-items-center text-center justify-content-center">
							<div className="col-lg-5 col-12 text-lg-right">
								<img
									src={process.env.PUBLIC_URL + cartoon404}
									alt=""
									style={{ maxWidth: '80%' }}
								/>
							</div>
							<div className="col-lg-5 col-12">
								<h2>{getErrorMessage()}</h2>
								<Link
									to={process.env.PUBLIC_URL + '/'}
									className="error-404-btn mt-20"
								>
									{t('Back to homepage')}
								</Link>
							</div>
						</div>
					</div>
				</div >
			</LayoutOne >
		</Fragment >
	);
};

NotFound.propTypes = {
	location: PropTypes.object,
	reason: PropTypes.string,
};

export default NotFound;
