import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from "react";
import HeaderOne from "../wrappers/header/HeaderOne";
import FooterOne from "../wrappers/footer/FooterOne";
import VoteBadge from "../wrappers/footer/ShopvoteWidget";
import GoogleBadge from "../pages/other/GoogleBadge";
import CookieConsent from "react-cookie-consent";
import { Link, useLocation } from 'react-router-dom';
import useWindowSize from '../helpers/useWindowSize.js';
import { useTranslation } from "react-i18next";
import TrustedShopsWidget from "../wrappers/footer/TrustedShopsWidget";
import useLocaleConfigSelectors from '../helpers/useLocaleConfigSelectors.js';
import { useGetConfigQuery } from '../redux/service/ioonsService.js';
import GTMConsentManager from '../helpers/GTMConsentManager';
import { useSelector } from 'react-redux';
import config_src from '../config/config.js';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { useGetSystemAllQuery } from '../redux/service/fastApiService.js';

const LayoutOne = ({ children }) => {
  const location = useLocation();
  const [width] = useWindowSize();
  const { t } = useTranslation();
  const headerSize = useSelector(state => state.header.size);

  const {
    getShopvoteDisplay: ShopvoteDisplay,
    getTrustedShopDisplay: TrustedShopDisplay,
    getWhatsAppBadgeDisplay: WhatsAppBadgeDisplay
  } = useLocaleConfigSelectors();

  const { data: configApi, isSuccess } = useGetConfigQuery();

  const {
    data: systemData
  } = useGetSystemAllQuery({}, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });
  const storeSupportTime = systemData?.support_time_mo_th || '';

  const [displayAdvent, setDisplayAdvent] = useState();
  useEffect(() => {
    if (isSuccess && configApi.displayAdvent)
      setDisplayAdvent(configApi.displayAdvent);
  }, [configApi]);

  const { updateConsent } = GTMConsentManager();
  const handleAccept = () => {
    document.body.style.overflow = 'auto';
    updateConsent(true);
  };
  const handleDecline = () => {
    document.body.style.overflow = 'auto';
    updateConsent(false);
  };
  const isAdmin = useSelector(state => state.user.isAdmin);

  const merchantId = config_src.googleMerchantId;

  return (
    <div className="d-flex flex-column min-vh-100" style={{ overflowX: "hidden" }}>
      <Fragment>
        {location.pathname !== `/${t('data-protection')}` && (
          <CookieConsent
            location="top"
            buttonText={t("Let's do it!")}
            enableDeclineButton
            declineButtonText={t('No, Keep it generic.')}
            cookieName="CookieConsentGA"
            cookieValue="granted"
            declineCookieValue="denied"
            onAccept={handleAccept}
            onDecline={handleDecline}
            expires={150}
            overlay
            buttonWrapperClasses="popup-google-content-cookie-consent-wrapper-buttons"
            buttonClasses="popup-google-content-cookie-consent-button-yes"
            declineButtonClasses="popup-google-content-cookie-consent-button"
            containerClasses="popup-google-content-cookie-consent"
            contentClasses="popup-google-content-cookie-consent-text"
          >
            <p className="popup-google-content-cookie-consent-text-title">{t('Ready to Personalize Your Experience?')}</p>
            <p>{t('We use cookies to enhance your experience, provide social media features and analyze traffic.')}</p>
            <p>
              {t('For more details on the use of cookies see our privacy policy.')} <Link to={`${process.env.PUBLIC_URL}/${t('data-protection')}`}>{t('Learn more')}</Link>
            </p>
            <p>{t('We also share information about your interactions on our site with our social networking, publicity and analytics partners. Do you agree to this?')}</p>
          </CookieConsent>
        )}
        {merchantId && (
          <GoogleBadge merchantId={merchantId} />
        )}
        <HeaderOne />
        <div style={{ height: headerSize }}></div>
        <div className="d-xl-none banner-bottom-color">
          {/* Icon group */}
          {displayAdvent == 1 && width < 1200 && location.pathname !== `${process.env.PUBLIC_URL}/advent-calendar` &&
            <div className='main-menu' style={{ padding: "13px 0" }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <span style={{ display: "inline-block", textAlign: "center", width: "100%" }}>
                  <Link to={{
                    pathname: `${process.env.PUBLIC_URL}/advent-calendar`
                  }}>
                    <span className="christmas-button" style={{ minWidth: "40%" }}>{t('Advent Calendar')}</span>
                  </Link>
                </span>
              </div>
            </div>
          }
        </div>
        {children}
        {TrustedShopDisplay.badge == true && (
          <>
            <TrustedShopsWidget />
          </>
        )}
        {ShopvoteDisplay.badge == true && (
          <>
            <VoteBadge />
          </>
        )}
        {WhatsAppBadgeDisplay &&
          <FloatingWhatsApp
            phoneNumber="+40770625886"
            accountName="Hausfabrik Ro"
            avatar="/assets/img/logo/logo_hf_at1.png"
            statusMessage={`online ${storeSupportTime}`}
            chatMessage="Buna! 🏡 Cu ce te putem ajuta?"
            placeholder="Scrie un mesaj..."
            allowClickAway
            allowEsc
            notification={false}
          />
        }
        <FooterOne />
      </Fragment>
    </div>
  );
};

LayoutOne.propTypes = {
  children: PropTypes.any
};

export default LayoutOne;
