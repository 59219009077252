import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useLocaleConfigSelectors from '../../helpers/useLocaleConfigSelectors';
import { setCountry } from '../../redux/slices/countrySlice';
import constants from '../../config/localStorageConstants';
import { useLocalStorageState } from 'ahooks';

const CountryDropdown = ({ countryDropdownInfo, disabled = false }) => {
  const selectedCountry = useSelector((state) => state.country?.selectedCountry);
	
	const { country_id,tax_title, excludeVAT } = constants;
	const isExcludeVAT = useSelector(state => state.user.excludeVAT);
	const [, setCountryId] = useLocalStorageState(country_id, {listenStorageChange: true})
	const [, setTaxTitle] = useLocalStorageState(tax_title, {listenStorageChange: true})
  const [isOpen, setIsOpen] = useState(false);
  const { getCountryFlag: countryFlag } = useLocaleConfigSelectors();
  const { t } = useTranslation();

  const dispatch = useDispatch();

    const handleSelect = (country) => {
      if (disabled) return;
      dispatch(setCountry(country));
      setTaxTitle(country.tax_title);
      setCountryId(country.id);
      setIsOpen(false);
    };

    const toggleDropdown = () => {
        if (disabled) {
            setIsOpen(false);
            return;
        }
        setIsOpen(!isOpen);
    }

  // Render different UI based on 'excludeVAT' and 'countryDropdownInfo.length'
  const excludeVATIsTrue = isExcludeVAT === 'true';

  // Apply disabled styles/logic
  const dropdownClassName = `dropdown-countries col-xl-2 ${disabled ? 'disabled' : ''}`;
  const dropdownListClassName = `dropdown-list ${disabled ? 'disabled' : ''}`;

  return excludeVATIsTrue ? (
    <div className={dropdownClassName} onClick={disabled ? null : () => setIsOpen(false)} style={disabled ? { cursor: 'not-allowed', opacity: 0.7 } : {}}>
      {selectedCountry ? (
        <>
          {t('Delivery Country')}
          <img src={countryFlag[selectedCountry.id]} alt='' className='dropdown-flag selected-flag' />
          <p style={{ color: 'white' }}>0% {t('VAT')}</p>
        </>
      ) : (
        <>
          <i className='fa fa-caret-down'></i> {t('Delivery Country')}
        </>
      )}
    </div>
  ) : (
    countryDropdownInfo.length > 1 ? (
      <div className={`dropdown col-xl-3 col-lg-3 col-md-6 col-6 ${disabled ? 'disabled' : ''}`}>
        <div className='dropdown-countries' onClick={toggleDropdown} style={disabled ? { cursor: 'not-allowed', opacity: 0.7 } : {}}>
          {selectedCountry ? (
            <>
              <i className='fa fa-caret-down'></i>
              {t('Delivery Country')}
              <img src={countryFlag[selectedCountry.id]} alt='' className='dropdown-flag selected-flag' />
            </>
          ) : (
            <>
              <i className='fa fa-caret-down'></i> {t('Delivery Country')}
            </>
          )}
        </div>
        {isOpen && !disabled && (
          <div className={dropdownListClassName}>
            {countryDropdownInfo.map((item, index) => (
              <div key={index}
                    className={`dropdown-item ${selectedCountry && selectedCountry.id === item.id ? 'selected-country' : ''}`}
                    onClick={() => handleSelect(item)}>
                <img src={countryFlag[item.id]} alt='' className='dropdown-flag' />
                {item.title} {item.tax_title}
              </div>
            ))}
          </div>
        )}
      </div>
    ) : (
      <div className={`dropdown-item-single ${disabled ? 'disabled' : ''}`} style={disabled ? { opacity: 0.7 } : {}}>
        {countryDropdownInfo.map((item, index) => (
          <React.Fragment key={index}>
            {t('Delivery Country')}
            <img src={countryFlag[item.id]} alt='' className='dropdown-flag selected-flag' />
          </React.Fragment>
        ))}
      </div>
    )
  );
};


export default CountryDropdown;
