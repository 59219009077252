import React, { useState, useEffect, useRef } from 'react';
import { Divide as Hamburger } from 'hamburger-react';
import { Link } from 'react-router-dom';
import '../../assets/icons/fontawesome';
import { CONTENT_API } from "../../helpers/product";
import { useTranslation } from "react-i18next";
import { useGetConfigQuery } from '../../redux/service/ioonsService.js';
import { useGetCategoriesHierarchyQuery } from '../../redux/service/fastApiService.js';
import useCategoriesInRTK from '../../hooks/useCategoriesInRTK.js';

function FullMenu() {
  const [open, setOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [openSubMenu2, setOpenSubMenu2] = useState(false);
  const [openSubMenu3, setOpenSubMenu3] = useState(false);
  const dropdownRef = useRef(null);
  const hamburgerRef = useRef(null);
  const [fullMenuHeight, setFullMenuHeight] = useState(0);
  const [fullMenuWidth, setFullMenuWidth] = useState(0);
  const { data: externalApiCategoriesCount, isLoading: isLoadingCount, isSuccess: isSuccessCat } = useCategoriesInRTK(true);
  const { data: externalApiCategories, isLoading: isLoadingCat, isSuccess: isSuccessCategories } = useGetCategoriesHierarchyQuery({}, { refetchOnMountOrArgChange: true });
  const handleButtonClick = () => {
    setOpen(!open);
    setOpenSubMenu(false);
    setOpenSubMenu2(false);
    setOpenSubMenu3(false);
  };

  const handleSubmenuClick = (index) => {
    setOpenSubMenu(index);
    if (openSubMenu !== index) {
      setOpenSubMenu2(false);
      setOpenSubMenu3(false);
    }
  };

  const handleSubmenu2Click = (index) => {
    setOpenSubMenu2(index);
    if (openSubMenu2 !== index) {
      setOpenSubMenu3(false);
    }
  };

  const handleSubmenu3Click = (index) => {
    setOpenSubMenu3(index);
  };

  const [contentArray, setContentArray] = useState([]);
  const [hardCodedLinks, setHardcodedLinks] = useState([]);
  const [categoryIconObject, setCategoryIconObject] = useState({});

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClickOutside);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const { data: configApi, isSuccess } = useGetConfigQuery();

  useEffect(() => {
    if (isSuccess) {
      if (configApi.left_menu_content_ids) {
        configApi.left_menu_content_ids.split(',').forEach(number => {
          CONTENT_API.getContent(number).then((response) => {
            if (response.id == number) {
              const newObject = { title: response.title, id: response.id, url: response?.rewriting_url?.url };
              setContentArray(prevArray => [...prevArray, newObject]);
            }
          });
        });
      }
      if (configApi.category_icon)
        setCategoryIconObject(configApi.category_icon);
      if (configApi.links_left_menu)
        setHardcodedLinks(configApi.links_left_menu);
    }
  }, [configApi]);

  function getScrollbarWidth() {
    // Create a div with a defined width and height, and overflow
    const scrollDiv = document.createElement('div');
    scrollDiv.style.width = '100px';
    scrollDiv.style.height = '100px';
    scrollDiv.style.overflow = 'scroll';
    scrollDiv.style.position = 'absolute';
    scrollDiv.style.top = '-9999px';
    document.body.appendChild(scrollDiv);

    // Measure the scrollbar width
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

    // Remove the div from the DOM
    document.body.removeChild(scrollDiv);

    return scrollbarWidth;
  }

  useEffect(() => {
    const scrollbarWidth = getScrollbarWidth();
    if (dropdownRef.current) {
      setFullMenuHeight(dropdownRef.current.clientHeight);
      setFullMenuWidth(dropdownRef.current.clientWidth);
    }
    if (open) {
      document.body.classList.add('overlay-open');
      document.body.style.paddingRight = `${scrollbarWidth}px`; // Add padding to compensate for scrollbar
      document.body.style.overflow = 'hidden'; // Disable scrolling
      // Add padding-right to .sticky-bar.stick div
      const stickyBar = document.querySelector('.sticky-bar.stick');
      if (stickyBar) {
        stickyBar.style.paddingRight = `${scrollbarWidth}px`;
      }
      const scrollTop = document.querySelector('.scroll-top.show');
      if (scrollTop) {
        scrollTop.style.marginRight = `${scrollbarWidth}px`;
      }
      const svBadges = document.querySelectorAll("#sv-vbadge-box");
      svBadges.forEach(svBadge => {
        svBadge.style.marginRight = `${scrollbarWidth}px`;
      });
      const svBadge = document.getElementById("sv-vbadge-box");
      if (svBadge) {
        svBadge.style.marginRight = `${scrollbarWidth}px`;
      }
      const svBadgePanel = document.getElementById("sv-panel-box");
      if (svBadgePanel) {
        svBadgePanel.style.marginRight = `${scrollbarWidth}px`;
      }
      // Dynamically find elements with class name "_t53mel" and apply styling
      const elementsWithT53melClass = document.querySelectorAll('._t53mel');
      elementsWithT53melClass.forEach(element => {
        element.style.setProperty('margin-right', `${scrollbarWidth}px`, 'important');
      });
      // Dynamically find elements with class name "_1dx6xh9" and apply styling
      const elementsWith1dx6xh9Class = document.querySelectorAll('._1dx6xh9');
      elementsWith1dx6xh9Class.forEach(element => {
        element.style.setProperty('margin-right', `${scrollbarWidth}px`, 'important');
      });
    } else {
      document.body.classList.remove('overlay-open');
      document.body.style.paddingRight = ''; // Reset padding
      document.body.style.overflow = ''; // Re-enable scrolling
      // Reset padding-right of .sticky-bar.stick div
      const stickyBar = document.querySelector('.sticky-bar.stick');
      if (stickyBar) {
        stickyBar.style.paddingRight = '';
      }
      const scrollTop = document.querySelector('.scroll-top.show');
      if (scrollTop) {
        scrollTop.style.marginRight = '';
      }
      const svBadges = document.querySelectorAll("#sv-vbadge-box");
      svBadges.forEach(svBadge => {
        svBadge.style.marginRight = ``;
      });
      const svBadge = document.getElementById("sv-vbadge-box");
      if (svBadge) {
        svBadge.style.marginRight = ``;
      }
      const svBadgePanel = document.getElementById("sv-panel-box");
      if (svBadgePanel) {
        svBadgePanel.style.marginRight = ``;
      }
      // Reset styling for elements with class name "_t53mel"
      const elementsWithT53melClass = document.querySelectorAll('._t53mel');
      elementsWithT53melClass.forEach(element => {
        element.style.marginRight = ``;
      });
      // Reset styling for elements with class name "_1dx6xh9"
      const elementsWith1dx6xh9Class = document.querySelectorAll('._1dx6xh9');
      elementsWith1dx6xh9Class.forEach(element => {
        element.style.marginRight = ``;
      });
    }

    // Cleanup function to reset overflow and padding on unmount or when open changes
    return () => {
      document.body.style.paddingRight = '';
      document.body.style.overflow = '';
      // Reset padding-right of .sticky-bar.stick div

      const stickyBar = document.querySelector('.sticky-bar.stick');
      if (stickyBar) {
        stickyBar.style.paddingRight = '';
      }
    };
  }, [open]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !hamburgerRef.current.contains(event.target)) {
      // Check if clicked target is the button or its child element
      if (event.target.closest(".button")) {
        return;
      }
      setOpen(false);
      setOpenSubMenu(false);
      setOpenSubMenu2(false);
      setOpenSubMenu3(false);
    }
  };

  const { t } = useTranslation();

  //Very important because if this is not here, we receive a big error because we will try to map over undefined.
  if (isLoadingCat || isLoadingCount) return null;

  if (!(isSuccessCat && isSuccessCategories)) return null;

  return (
    <div className={`container ${open ? 'banner-menu-open-color' : ''}`}>
      <div ref={hamburgerRef} className='main-menu' onClick={() => handleButtonClick()}><nav><ul><li>
        <a style={{ display: "flex", alignItems: "center" }}>
          <span style={{ display: "inline-block" }}><Hamburger toggled={open} size={24} /></span>
          <span style={{ display: "inline-block", textAlign: "center" }}>
            {t('MENU')}
          </span>
        </a>
      </li></ul></nav></div>
      {open && (
        <div ref={dropdownRef} className="dropdown-fullmenu">
          <ul>
            {externalApiCategories.map((item, index) => (
              externalApiCategoriesCount[item.Id]?.count > 0 && (
                item.children && item.children.some(child => externalApiCategoriesCount[child.Id]?.count > 0) ? (
                  <li key={item.Id}>
                    <Link to={{
                      pathname: `${process.env.PUBLIC_URL}/${item.URL}`,
                      state: [`${item.Title}`, `${item.Id}`]
                    }} onClick={() => handleButtonClick()}>
                      <span className="category-icon-n-title col-10">
                        <span className="category-icon col-2">
                          {categoryIconObject[item.Id] ? (
                            <i className={categoryIconObject[item.Id]}></i>
                          ) : (
                            <i className="icon-052-oval"></i>
                          )}
                        </span>
                        <span className="category-title col-10" style={{ letterSpacing: "0.8px", textTransform: "uppercase" }}>{item.Title}</span>
                      </span>
                    </Link>
                    <span onClick={() => handleSubmenuClick(index)} className={`category-arrow col-2 ${openSubMenu === index ? 'colorized' : ''}`}><i className={`fa ${openSubMenu === index ? 'fa-angle-double-right' : 'fa-angle-right'}`} aria-hidden="true"></i></span>
                    {openSubMenu === index &&
                      <div className="submenu my-div" style={{ minHeight: `${fullMenuHeight}px`, minWidth: `${fullMenuWidth}px` }}>
                        <ul>
                          {item.children.map((child, index) => (
                            externalApiCategoriesCount[child.Id]?.count > 0 && (
                              child.children && child.children.some(child => externalApiCategoriesCount[child.Id]?.count > 0) ? (
                                <li key={child.Id}>
                                  <Link to={{
                                    pathname: `${process.env.PUBLIC_URL}/${child.URL}`,
                                    state: [`${child.Title}`, `${child.Id}`]
                                  }} onClick={() => handleButtonClick()}>
                                    <span className="category-icon-n-title col-10">
                                      <span className="category-icon col-2">
                                        {categoryIconObject[child.Id] ? (
                                          <i className={categoryIconObject[child.Id]}></i>
                                        ) : (
                                          <i className="icon-052-oval"></i>
                                        )}
                                      </span>
                                      <span className="category-title col-10">{child.Title}</span>
                                    </span>
                                  </Link>
                                  <span onClick={() => handleSubmenu2Click(index)} className={`category-arrow col-2 ${openSubMenu2 === index ? 'colorized' : ''}`}><i className={`fa ${openSubMenu2 === index ? 'fa-angle-double-right' : 'fa-angle-right'}`} aria-hidden="true"></i></span>
                                  {openSubMenu2 === index &&
                                    <div className="submenu" style={{ minHeight: `${fullMenuHeight}px`, minWidth: `${fullMenuWidth}px` }}>
                                      <ul>
                                        {child.children.map((nephew, index) => (
                                          externalApiCategoriesCount[nephew.Id]?.count > 0 && (
                                            nephew.children && nephew.children.some(child => externalApiCategoriesCount[child.Id]?.count > 0) ? (
                                              <li key={nephew.Id}>
                                                <Link to={{
                                                  pathname: `${process.env.PUBLIC_URL}/${nephew.URL}`,
                                                  state: [`${nephew.Title}`, `${nephew.Id}`]
                                                }} onClick={() => handleButtonClick()}>
                                                  <span className="category-icon-n-title col-10">
                                                    <span className="category-icon col-2">
                                                      {categoryIconObject[nephew.Id] ? (
                                                        <i className={categoryIconObject[nephew.Id]}></i>
                                                      ) : (
                                                        <i className="icon-052-oval"></i>
                                                      )}
                                                    </span>
                                                    <span className="category-title col-10">{nephew.Title}</span>
                                                  </span>
                                                </Link>
                                                <span onClick={() => handleSubmenu3Click(index)} className={`category-arrow col-2 ${openSubMenu3 === index ? 'colorized' : ''}`}><i className={`fa ${openSubMenu3 === index ? 'fa-angle-double-right' : 'fa-angle-right'}`} aria-hidden="true"></i></span>
                                                {openSubMenu3 === index &&
                                                  <div className="submenu" style={{ minHeight: `${fullMenuHeight}px`, minWidth: `${fullMenuWidth}px` }}>
                                                    <ul>
                                                      {nephew.children.map((grandson) => (
                                                        externalApiCategoriesCount[grandson.Id]?.count > 0 && (
                                                          <Link to={{
                                                            pathname: `${process.env.PUBLIC_URL}/${grandson.URL}`,
                                                            state: [`${grandson.Title}`, `${grandson.Id}`]
                                                          }} key={grandson.Id} onClick={() => handleButtonClick()} className="product-banner">
                                                            <li className="category-without-arrow col-12">
                                                              <span className="col-10">
                                                                <span className="category-icon col-2">
                                                                  {categoryIconObject[grandson.Id] ? (
                                                                    <i className={categoryIconObject[grandson.Id]}></i>
                                                                  ) : (
                                                                    <i className="icon-052-oval"></i>
                                                                  )}
                                                                </span>
                                                                <span className="category-title col-10">{grandson.Title}</span>
                                                              </span>
                                                            </li>
                                                          </Link>
                                                        )
                                                      ))}
                                                    </ul>
                                                  </div>
                                                }
                                              </li>
                                            ) : (
                                              <Link to={{
                                                pathname: `${process.env.PUBLIC_URL}/${nephew.URL}`,
                                                state: [`${nephew.Title}`, `${nephew.Id}`]
                                              }} key={nephew.Id} onClick={() => handleButtonClick()} className="product-banner">
                                                <li className="category-without-arrow col-12">
                                                  <span className="col-10">
                                                    <span className="category-icon col-2">
                                                      {categoryIconObject[nephew.Id] ? (
                                                        <i className={categoryIconObject[nephew.Id]}></i>
                                                      ) : (
                                                        <i className="icon-052-oval"></i>
                                                      )}
                                                    </span>
                                                    <span className="category-title col-10">{nephew.Title}</span>
                                                  </span>
                                                </li>
                                              </Link>
                                            )
                                          )
                                        ))}
                                      </ul>
                                    </div>
                                  }
                                </li>
                              ) : (
                                <Link to={{
                                  pathname: `${process.env.PUBLIC_URL}/${child.URL}`,
                                  state: [`${child.Title}`, `${child.Id}`]
                                }} key={child.Id} onClick={() => handleButtonClick()} className="product-banner">
                                  <li className="category-without-arrow col-12">
                                    <span className="col-10">
                                      <span className="category-icon col-2">
                                        {categoryIconObject[child.Id] ? (
                                          <i className={categoryIconObject[child.Id]}></i>
                                        ) : (
                                          <i className="icon-052-oval"></i>
                                        )}
                                      </span>
                                      <span className="category-title col-10">{child.Title}</span>
                                    </span>
                                  </li>
                                </Link>
                              )
                            )
                          ))}
                        </ul>
                      </div>
                    }
                  </li>
                ) : (
                  <li key={item.Id}>
                    <Link to={{
                      pathname: `${process.env.PUBLIC_URL}/${item.URL}`,
                      state: [`${item.Title}`, `${item.Id}`]
                    }} onClick={() => handleButtonClick()} className="product-banner">
                      <li className="category-without-arrow col-12">
                        <span className="col-10">
                          <span className="category-icon col-2">
                            {categoryIconObject[item.Id] ? (
                              <i className={categoryIconObject[item.Id]}></i>
                            ) : (
                              <i className="icon-052-oval"></i>
                            )}
                          </span>
                          <span className="category-title col-10" style={{ letterSpacing: "0.8px", textTransform: "uppercase" }}>{item.Title}</span>
                        </span>
                      </li>
                    </Link>
                  </li>
                )
              )
            ))}
            {contentArray.map((item, index) => (
              <li key={index}>
                <Link to={{
                  pathname: `${process.env.PUBLIC_URL}/${item?.url}`,
                  state: [`${item.title}`, `${item.id}`]
                }} onClick={() => handleButtonClick()} className="product-banner">
                  <span className="category-without-arrow col-12">
                    <span className="col-10">
                      <span className="category-icon col-2">
                        {categoryIconObject[item.Id] ? (
                          <i className={categoryIconObject[item.Id]}></i>
                        ) : (
                          <i className="icon-052-oval"></i>
                        )}
                      </span>
                      <span className="category-title col-10" style={{ letterSpacing: "0.8px", textTransform: "uppercase" }}>{item.title}</span>
                    </span>
                  </span>
                </Link>
              </li>
            ))}
            {hardCodedLinks.map((link, index) => (
              <li key={index}>
                <Link to={{
                  pathname: `${process.env.PUBLIC_URL}/${link?.href}`,
                  state: [`${link.title}`]
                }} onClick={() => handleButtonClick()} className="product-banner">
                  <span className="category-without-arrow col-12">
                    <span className="col-10">
                      <span className="category-icon col-2">
                        {categoryIconObject[link.Id] ? (
                          <i className={categoryIconObject[link.Id]}></i>
                        ) : (
                          <i className="icon-052-oval"></i>
                        )}
                      </span>
                      <span className="category-title col-10" style={{ letterSpacing: "0.8px", textTransform: "uppercase" }}>{link.title}</span>
                    </span>
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FullMenu;
