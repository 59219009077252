import React, { lazy, Suspense, useEffect } from 'react';
import { ToastProvider } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TagManager from 'react-gtm-module/dist/TagManager.js';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import translationsRo from './assets/i18n_ioons/translations_ro.json';
import translationsDe from './assets/i18n_ioons/translations_de.json';
import useLocalRedux from './helpers/useLocalRedux';
import useLocaleConfigSelectors from './helpers/useLocaleConfigSelectors';
import TokenProvider from './TokenProvider';
import NestedRouter from './NestedRouter';
import config_src from './config/config.js';
import { setEmpty } from './redux/slices/userSlice';
import { resetAppState, setAppVersion, setLastLocation, setShowNewVersionToast } from './redux/slices/appSlice';
import useWindowSize from './helpers/useWindowSize';
import { validateConfig } from './config/config_hard.js';
import LoadingStatus from './components/LoadingStatus.jsx';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router';
import { useZenDesk } from './helpers/UseZenDesk.js';
import { useGetConfigQuery } from './redux/service/ioonsService';

// home pages
const IOONSHOME = lazy(() => import("./pages/home/IOONS_HOME"));
// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

// blog pages
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));

// other pages
const AdventCalendar = lazy(() => import("./pages/other/AdventCalendar/AdventCalendar"));
const About = lazy(() => import("./pages/other/About"));
const Bezahlen = lazy(() => import("./pages/other/Bezahlen"));
const Lieferung = lazy(() => import("./pages/other/Lieferung"));
const Datenschutz = lazy(() => import("./pages/other/Datenschutz"));
const Widerrufsrecht = lazy(() => import("./pages/other/Widerrufsrecht"));
const AGB = lazy(() => import("./pages/other/AGB"));
const FAQ = lazy(() => import("./pages/other/Faq"));
const Presse = lazy(() => import("./pages/other/Presse"));
const Impressum = lazy(() => import("./pages/other/Impressum"));
const Versandkosten = lazy(() => import("./pages/other/Versandkosten"));
const Contact = lazy(() => import("./pages/other/Contact"));
const Newsletter = lazy(() => import("./pages/other/Newsletter"));
const NewsletterUnsubscribe = lazy(() => import("./pages/other/NewsletterUnsubscribe"));
const MyAccount = lazy(() => import("./pages/other/MyAccount/MyAccount"));
const MyOrder = lazy(() => import("./pages/other/MyAccount/MyOrder"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const LogOut = lazy(() => import("./pages/other/LogOut.jsx"));
const ForgotPassword = lazy(() => import("./pages/other/ForgotPassword"));
const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const OrderPlaced = lazy(() => import("./pages/other/OrderPlaced"));
const OrderFailed = lazy(() => import("./pages/other/OrderFailed"));
const ElasticSearchPage = lazy(() => import("./pages/shop/ElasticSearchPage"));
const AbandonedCart = lazy(() => import("./pages/other/AbandonedCart"));

// const language = navigator.language.split("-")[0];
const language = config_src.language;
i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		// the translations
		// (tip move them in a JSON file and import them,
		// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
		resources: {
			de: {
				translation: translationsDe
			},
			ro: {
				translation: translationsRo
			}
		},
		lng: language, // if you're using a language detector, do not define the lng option
		// fallbackLng: "en",
		// debug: true,
		interpolation: {
			escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
		}
	});

const APP_VERSION = config_src.version;

function App() {
	useGetConfigQuery({}, {});
	const { t } = useTranslation();
	const dispatch = useDispatch();
	useLocalRedux();
	if (appVersion !== undefined) {
		dispatch(setAppVersion(APP_VERSION));
	}
	useZenDesk();
	const {
		getWiderrufsformularPdfLink,
		getCurrencyCode,
		getCurrencySymbol,
	} = useLocaleConfigSelectors();
	const { version: appVersion, showNewVersionToast } = useSelector((state) => state.app);
	const handleToast = () => t('Our website has been updated');

	function invalidateStore() {
		dispatch(setLastLocation(window.location.pathname));
		dispatch(setEmpty());
		dispatch(resetAppState());
		localStorage.clear();
		window.location.reload();
	}

	useEffect(() => {
		if (getCurrencyCode !== null || getCurrencySymbol !== null) {
			const currencyInfo = {
				currencySymbol: getCurrencySymbol,
				currencyName: getCurrencyCode,
				currencyRate: 1,
			};

			dispatch({
				type: 'SET_CURRENCY',
				payload: currencyInfo,
			});
		}
	}, [getCurrencyCode, getCurrencySymbol]);
	const [width] = useWindowSize();
	const { gtmId } = config_src;
	const tagManagerArgs = { gtmId }

	useEffect(() => {
		if (appVersion !== APP_VERSION) {
			dispatch(setAppVersion(APP_VERSION));

			const timer = setTimeout(() => {
				invalidateStore();
			}, 0);
			return () => {
				clearTimeout(timer);
				toast.dismiss(toastId);
			};
		}

		let toastId = null;
		if (showNewVersionToast) {
			const toastMessage = handleToast();
			toastId = toast.info(
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<p style={{ marginRight: '10px', marginBottom: 0 }}>{toastMessage}</p>
					<button
						className="update-version-button theme-color-hover-background-hover"
						onClick={() => {
							toast.dismiss(toastId);
						}}
					>
						OK
					</button>
				</div>,
				{
					pauseOnHover: false,
					draggable: false,
					pauseOnFocusLoss: false,
					closeOnClick: false,
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 15000,
				}
			);
			dispatch(setShowNewVersionToast(false));
		}

		TagManager.initialize(tagManagerArgs);

		const validConfig = validateConfig(config_src)
		if (!validConfig.isValid) {
			console.error('Some required config properties are missing!');
			alert(`This are missing from config.js: ${validConfig?.missingConfigs.join(', ')}`);
		}
	}, []);

	const { PUBLIC_URL } = process.env;

	return (
		<TokenProvider>
			<ToastProvider placement={width > 767 ? "top-right" : "top-center"}>
				<BreadcrumbsProvider>
					<Router>
						<Suspense fallback={<LoadingStatus />}>
							<Routes>
								<Route
									path={`${PUBLIC_URL}/`}
									element={<IOONSHOME />}
								/>

								<Route
									path={`${PUBLIC_URL}/advent-calendar`}
									element={<AdventCalendar />}
								/>

								<Route
									path={`${PUBLIC_URL}/shop-grid-standard/:type`}
									element={<ShopGridStandard />}
								/>

								<Route
									path={`${PUBLIC_URL}/product/:id`}
									element={<Product />}
								/>

								<Route
									path={`${PUBLIC_URL}/blog`}
									element={<BlogStandard />}
								/>

								<Route
									path={`${PUBLIC_URL}/about`}
									element={<About />}
								/>

								<Route
									path={`${PUBLIC_URL}/bezahlen`}
									element={<Bezahlen />}
								/>

								<Route
									path={`${PUBLIC_URL}/lieferung`}
									element={<Lieferung />}
								/>

								<Route
									path={`${PUBLIC_URL}/datenschutz`}
									element={<Datenschutz />}
								/>

								<Route
									path={`${PUBLIC_URL}/agb.html`}
									element={<AGB />}
								/>

								<Route
									path={`${PUBLIC_URL}/faq`}
									element={<FAQ />}
								/>

								<Route
									path={`${PUBLIC_URL}/presse`}
									element={<Presse />}
								/>

								<Route
									path={`${PUBLIC_URL}/Impressum`}
									element={<Impressum />}
								/>

								<Route
									path={`${PUBLIC_URL}/widerrufsrecht`}
									element={<Widerrufsrecht />}
								/>

								<Route
									path="/widerrufsformular"
									element={<Navigate to={getWiderrufsformularPdfLink} replace />}
								/>

								<Route
									path={`${PUBLIC_URL}/contact`}
									element={<Contact />}
								/>

								<Route
									path={`${PUBLIC_URL}/newsletter`}
									element={<Newsletter />}
								/>

								<Route
									path={`${PUBLIC_URL}/newsletter-unsubscribe`}
									element={<NewsletterUnsubscribe />}
								/>

								<Route
									path={`${PUBLIC_URL}/account/order/:id`}
									element={<MyOrder />}
								/>

								<Route
									path={`${PUBLIC_URL}/account`}
									element={<MyAccount />}
								/>

								<Route
									path={`${PUBLIC_URL}/guest/invoice/:ref/:hash`}
									element={<MyOrder />}
								/>

								<Route
									path={`${PUBLIC_URL}/versandkosten`}
									element={<Versandkosten />}
								/>

								<Route
									path={`${PUBLIC_URL}/login-register`}
									element={<LoginRegister />}
								/>

								<Route
									path={`${PUBLIC_URL}/logout`}
									element={<LogOut />}
								/>

								<Route
									path={`${PUBLIC_URL}/forgot-password`}
									element={<ForgotPassword />}
								/>

								<Route
									path={`${PUBLIC_URL}/cart`}
									element={<Cart />}
								/>

								<Route
									path={`${PUBLIC_URL}/wishlist`}
									element={<Wishlist />}
								/>

								<Route
									path={`${PUBLIC_URL}/compare`}
									element={<Compare />}
								/>

								<Route
									path={`${PUBLIC_URL}/checkout`}
									element={<Checkout />}
								/>

								<Route
									path={`${PUBLIC_URL}/order-placed`}
									element={<OrderPlaced />}
								/>

								<Route
									path={`${PUBLIC_URL}/order-failed`}
									element={<OrderFailed />}
								/>

								<Route
									path={`${PUBLIC_URL}/not-found`}
									element={<NotFound />}
								/>

								<Route
									path={`${PUBLIC_URL}/404`}
									element={<NotFound />}
								/>

								<Route
									path={`${PUBLIC_URL}/search/:value`}
									element={<ElasticSearchPage />}
								/>

								<Route
									path={`${PUBLIC_URL}/back-to-cart/:token`}
									element={<AbandonedCart />}
								/>

								<Route
									path={`${PUBLIC_URL}/*`}
									element={<NestedRouter />}
								/>
							</Routes>
						</Suspense>
					</Router>
				</BreadcrumbsProvider>
				<ToastContainer />
			</ToastProvider>
		</TokenProvider>
	);
};

export default App;
