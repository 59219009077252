export let requiredConfigs = ['apiUrl', 'fastApi', 'token', 'language', 'locale'];
export const validateConfig = (config) => {
	const missingConfigs = requiredConfigs.filter(prop => {
		const value = config[prop];
		return value == null || value === '';
	});

	return {
		isValid: missingConfigs.length === 0,
		missingConfigs,
	};
};

export const commonConfig = {
	version: '1.0.2.2',
	mode: process.env.NODE_ENV,  // Use Node environment
	zendeskKey: '',
	gtmId: '',
	amazonStoreId: '',
	amazonMerchantId: '',
	amazonPublicKeyId: '',
	geolocationApiDomain: '',
	stripePublicKey: '',
	refetchTimingLong: 30,
	refetchTimingShort: 60,
	keywordGroupString: 'badges',
	countryDefault: { id: 13, title: 'Österreich', tax_title: '20% Ust.' },
	locale_config: [
		'lang_id',
		'lang_locale',
		'lang_url',
		'country_id',
		'tax_tax_id',
		'tax_tax_title',
		'tax_serialized_requirements',
		'store_name',
		'lang_code',
		'currency_currency_code',
		'loading_gif',
		'currency_currency_symbol',
		'site_key',
		'captcha_site_key',
		'logo_image',
		'sal',
		'anpc',
		'display_buttons_ANPC',
		'default_product_image',
		'widerrufsformular_pdf_link',
		'content_ids',
		'logo_footer',
		'logo_header',
		'home_team_img',
		'homepage_products',
		'hero_slider_twelve',
		'vorkasse_info',
		'country_title',
		'ioons_mail_picture',
		'ioons_facebook_url',
		'ioons_instagram_url',
		'ioons_youtube_url',
		'shopvote_shop_id',
		'trusted_shop_id',
		'shopvote_display',
		'trustedshop_display',
		'phone_regex',
		'footer_image_1',
		'footer_image_2',
		'footer_image_3',
		'footer_image_4',
		'feature_icon_eight',
		'testimonial_one',
		'guarantee_links',
		'advent',
		'advent_bg',
		'advent_gift',
		'vat_min_length',
		'textInCart',
		'multiplier',
		'content_with_images',
		'best_sellers_products_homepage_ids',
		'brand_logos_homepage',
		'banners_homepage',
		'country_visible',
		'config_min_price',
		'country_flag',
		'display_country_header',
		'is_phone_required',
		'series_menu',
		'ioons_tiktok_url',
		'text-header-info',
		'categories_0_vat',
		'categories_title_0_vat',
		'price_request_product_ids',
		'whatsapp_badge_display',
		'country_isoalpha2',
		'cartoon_404'
	]
};

export const getCountryConfigFromLocale = (locale) => {
	const countryConfigs = {
		"de_DE": { id: 5, isoalpha2: 'DE', title: 'Deutschland', tax_title: '19% Ust.' },
		"de_AT": { id: 13, isoalpha2: 'AT', title: 'Österreich', tax_title: '20% Ust.' },
		"ro_RO": { id: 146, isoalpha2: 'RO', title: 'Romania', tax_title: '19% TVA' },
	};

	return countryConfigs[locale] || countryConfigs['de_AT']; // Default to Austria if locale not found
};
