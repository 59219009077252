import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_PRODUCT_DELIVERY_DELAY, CONTENT_API } from "../../helpers/product";
import { useGetConfigQuery } from '../../redux/service/ioonsService.js';
import {
    useGetBrandListQuery,
    useGetCategoriesHierarchyQuery,
} from '../../redux/service/fastApiService.js';
import useCategoriesInRTK from '../../hooks/useCategoriesInRTK.js';

function NavMenu() {
    const { data: externalApiCategoriesCount, isLoading: loadingCategoriesCount, isSuccess: isSuccessCategoriesCount } = useCategoriesInRTK(true);
    const { data: externalApiCategories, isLoading: loadingCategories, isSuccess: isSuccessCategories } = useGetCategoriesHierarchyQuery({}, {
        refetchOnMountOrArgChange: true,
    });
    const [menu_category_ids, setMenu_category_ids] = useState([]);
    const [menu_content_ids, setMenu_content_ids] = useState([]);
    const [links_menu, setLinks_menu] = useState([]);
    const [brands, setBrands] = useState([]);

    const { data: configApi, isSuccess, isLoading } = useGetConfigQuery();
    /*const { data: brandsApi, isSuccess: isBrandsSuccess, isLoading:isBrandLoading } = useGetBrandListQuery();

    useEffect(() => {
        if (isBrandsSuccess) {
            setBrands(brandsApi);
        }
    }, [isBrandsSuccess]);*/

    useEffect(() => {
        if (isSuccess && !isLoading) {
            if (configApi.menu_category_ids) {
                setMenu_category_ids(configApi.menu_category_ids);
            }
            if (configApi.right_menu_content_ids) {
                configApi.right_menu_content_ids.split(',').forEach(number => {
                    CONTENT_API.getContent(number).then((response) => {
                        if (response.id == number) {
                            const newObject = { title: response.title, id: response.id, url: response?.rewriting_url?.url };
                            setMenu_content_ids(prevArray => [...prevArray, newObject]);
                        }
                    });
                });
            }
            if (configApi.links_right_menu) {
                setLinks_menu(configApi.links_right_menu);
            }
        }
    }, [configApi]);

    // useEffect(() => {
    //     API_PRODUCT_DELIVERY_DELAY.getContent().then((data) => {
    //         if (data.menu_category_ids) {
    //             setMenu_category_ids(data.menu_category_ids);
    //         }
    //         if (data.right_menu_content_ids) {
    //             data.right_menu_content_ids.split(',').forEach(number => {
    //                 CONTENT_API.getContent(number).then((response) => {
    //                     if (response.id == number) {
    //                         const newObject = { title: response.title, id: response.id, url: response?.rewriting_url?.url };
    //                         setMenu_content_ids(prevArray => [...prevArray, newObject]);
    //                     }
    //                 });
    //             });
    //         }
    //         if (data.links_right_menu) {
    //             setLinks_menu(data.links_right_menu);
    //         }
    //     })
    // }, [])

    if (loadingCategories || loadingCategoriesCount) return null;
    if (!externalApiCategories || !externalApiCategoriesCount) return null;
    if (!isSuccessCategories || !isSuccessCategoriesCount) return null;

    return (
        <div className="main-menu">
            <nav>
                <ul>
                    {isSuccessCategories && externalApiCategories.map((item, index) => (
                        menu_category_ids.includes(item.Id) &&
                        <li key={`${item.Id}-${index}`}>
                            <Link to={{
                                pathname: `${process.env.PUBLIC_URL}/${item.URL}`,
                                state: [`${item.Title}`, `${item.Id}`]
                            }}>
                                {item.Title.toUpperCase()}
                            </Link>
                            <i className="fa fa-angle-down arrow_color" />
                            <ul className="mega-menu mega-menu-padding">
                                {item.children && item.children.map(child => (
                                    externalApiCategoriesCount[child.Id]?.count > 0 &&
                                    <li key={`${child.Id}-${item.Id}`}>
                                        <ul>
                                            <li className="mega-menu-title">
                                                <Link to={{
                                                    pathname: `${process.env.PUBLIC_URL}/${child.URL}`,
                                                    state: [`${child.Title}`, `${child.Id}`]
                                                }}>
                                                    {child.Title}
                                                </Link>
                                            </li>
                                            {child.children && child.children.filter(child => externalApiCategoriesCount[child.Id]?.count > 0).slice(0, 5).map((nephew, indexNephew) => (
                                                <li key={`${nephew.Id}-${indexNephew}`}>
                                                    <Link to={{
                                                        pathname: `${process.env.PUBLIC_URL}/${nephew.URL}`,
                                                        state: [`${nephew.Title}`, `${nephew.Id}`]
                                                    }}>
                                                        {nephew.Title}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                    {/* Adding the Brands dropdown */}
                    {/* <li>
                      <Link to="#">
                          BRANDS
                      </Link>
                      <i className="fa fa-angle-down arrow_color" />
                      <ul className="mega-menu mega-menu-padding">
                          {
                            brands.map((brand) => (
                              <li key={brand.brand_id}>
                                  <Link to={{
                                      pathname: `${process.env.PUBLIC_URL}/${brand.brand_url}`,
                                      state: [`${brand.brand_title}`, `${brand.brand_id}`]
                                  }}
                                        style={{ color: 'black' }} // Add inline style to apply black text color
                                  >
                                      {brand.brand_title}
                                  </Link>
                              </li>
                            ))
                          }
                      </ul>
                  </li>*/}
                    {menu_content_ids.map((item, index) => (
                        <li key={`menu-content-${index}`}>
                            <Link to={{
                                pathname: `${process.env.PUBLIC_URL}/${item?.url}`,
                                state: [`${item.title}`, `${item.id}`]
                            }}>
                                {item.title.toUpperCase()}
                            </Link>
                        </li>
                    ))}
                    {links_menu.map((link, index) => (
                        <li key={`link-menu-${index}`}>
                            <Link to={{
                                pathname: `${process.env.PUBLIC_URL}/${link?.href}`,
                                state: [`${link.title}`]
                            }}>
                                {link.title.toUpperCase()}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>

    );
}

export default NavMenu;
