import { SET_CURRENCY } from "../actions/currencyActions";

const initState = {
  currencySymbol: "€",
  currencyName: "EUR",
  currencyRate: 1
};

const currencyReducer = (state = initState, action) => {
  if (action.type === SET_CURRENCY)
    return {
      ...state,
      currencySymbol: action.payload.currencySymbol,
      currencyRate: action.payload.currencyRate,
      currencyName: action.payload.currencyName
    };

  return state;
};

export default currencyReducer;
