import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MenuCart from './sub-components/MenuCart';
import { deleteFromCart } from '../../redux/actions/cartActions';
import { fetchToken, removeToken } from '../../helpers/session.js';
import { useGetSystemAllQuery } from '../../redux/service/fastApiService.js';
import { setEmpty } from '../../redux/slices/userSlice';
import useWindowSize from '../../helpers/useWindowSize.js';
import { setLastLocation } from '../../redux/slices/appSlice.js';

const IconGroup = ({
    currency,
    cartData,
    deleteFromCart,
    iconWhiteClass,
    toggleSearchBar
}) => {
    const { t } = useTranslation();
    const [width] = useWindowSize();
    const [accountDropDown, setAccountDropDown] = useState(false);
    const [cartDropDown, setCartDropDown] = useState(false);
    const [shopCartValue, setShopCartValue] = useState(false);
    const dispatch = useDispatch();
    const triggerMobileMenu = () => {
        const offcanvasMobileMenu = document.querySelector(
            '#offcanvas-mobile-menu',
        );
        offcanvasMobileMenu.classList.add('active');
    };
    const signOut = () => {
        removeToken();
        dispatch(setEmpty());
    };

    const {
        data: systemData,
        isSuccess,
        isLoading,
        isError,
        error,
        refetch
    } = useGetSystemAllQuery({}, {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
        refetchOnReconnect: true,
    });

    // Safely access system data properties
    const storePhone = systemData?.store_phone || '';
    const storeSupportTime = systemData?.support_time_mo_th || '';

    // Handle retry for system config
    const handleRetrySystemConfig = () => {
        refetch();
    };

    const path = useLocation().pathname;
    const profileNameRTK = useSelector(state => state.user?.profileName);
    const token = useSelector(state => state.user?.token);

    useEffect(() => {
        if (path !== '/login-register' && path !== '/forgot-password')
            dispatch(setLastLocation(path));
    }, [path]);

    let itemsInCart = 0;
    const refZero = useRef(null);

    const [clicked, setClicked] = useState(false);

    const refOne = useRef(null);
    const refOne2 = useRef(null);

    const handleAccountDropdownClick = () => {
        setAccountDropDown(!accountDropDown);
    };

    const handleCartDropdownClick = () => {
        setCartDropDown(!cartDropDown);
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if ((refOne?.current?.contains(e.target)) || refOne2?.current?.contains(e.target))
                setClicked(true);
            else {
                setClicked(false);
                setAccountDropDown(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            // Clean up the event listener when the component is unmounted
            document.removeEventListener('click', handleClickOutside);
        };
    }, [clicked]);

    const [isPhoneHoursExpanded, setIsPhoneHoursExpanded] = useState(false);
    const phoneHoursWrapperRef = useRef(null);
    const togglePhoneHours = () => {
        setIsPhoneHoursExpanded(!isPhoneHoursExpanded);
    };
    const handleClickOutside = (event) => {
        if (phoneHoursWrapperRef.current && !phoneHoursWrapperRef.current.contains(event.target)) {
            // Clicked outside the phone-hours-wrapper, toggle the state
            setIsPhoneHoursExpanded(false);
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    const formatNumber = (num) => {
        const kPart = Math.floor(num / 1000);
        return kPart === 0 ? num : `${kPart}k`;
    };

    return (
        <div className={`header-right-wrap ${iconWhiteClass || ''}`}>
            {width < 992 && (
                <div className="same-style cart-wrap searchIconBox">
                    <button onClick={toggleSearchBar}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" role="img"
                            className="searchIcon">
                            <path
                                d="m19.687 18.272-5.431-5.329-.005-.003a7.7 7.7 0 0 0 1.914-5.073C16.165 3.522 12.546 0 8.082 0S0 3.522 0 7.867s3.619 7.867 8.083 7.867c1.754 0 3.373-.55 4.698-1.474.028.036.042.078.075.11l5.431 5.329a1 1 0 0 0 1.414-.014 1 1 0 0 0-.014-1.414ZM8.083 13.735C4.729 13.735 2 11.103 2 7.868s2.729-5.867 6.083-5.867 6.083 2.632 6.083 5.867c0 1.649-.691 3.177-1.945 4.3a6.18 6.18 0 0 1-4.138 1.567"
                                className="cls-1"></path>
                        </svg>
                    </button>
                </div>
            )}
            <div className="profile-wrapper">
                <div className="first-three-icons">
                    <div className="same-style cart-wrap account-setting real-margin" style={{ display: 'flex', width: '120px' }}>
                        <button>
                            <a href={width > 767 ? undefined : (storePhone ? `tel:${storePhone}` : undefined)}>
                                <div
                                    className={`phone-hours-wrapper ${isPhoneHoursExpanded ? 'phone-hours-wrapper-hover-bg' : ''} ${isLoading ? 'loading' : ''} ${isError ? 'error' : ''}`}
                                    ref={phoneHoursWrapperRef}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: (width > 767 && isPhoneHoursExpanded) ? '189.5px' : '122px',
                                        transition: 'width 0.3s ease',
                                    }}
                                    onClick={isError ? handleRetrySystemConfig : togglePhoneHours}
                                >
                                    {isLoading && (
                                        <div className="phone-loading-indicator">
                                            <div className="spinner"></div>
                                        </div>
                                    )}

                                    {isError && (
                                        <div className="phone-error-indicator"
                                            title={`${t('Error loading data')}: ${error?.message || t('Unknown error')}`}>
                                            <i className="pe-7s-refresh-2" /> {/* Refresh icon for retry */}
                                        </div>
                                    )}
                                    {!isLoading && !isError && (
                                        <>
                                            {width > 767 && (
                                                <span
                                                    className={`count-style ${isPhoneHoursExpanded ? 'count-style-animation-appear' : 'count-style-animation-disappear'}`}>
                                                    <i className="pe-7s-call" />
                                                </span>
                                            )}
                                            <span className="big-phone">
                                                <i className="pe-7s-call" style={{ display: (width > 767 && isPhoneHoursExpanded) ? 'none' : 'block' }} />
                                            </span>
                                            <span className="phone-hours-header"
                                                style={{ display: (width > 767 && isPhoneHoursExpanded) ? 'none' : 'block' }}>
                                                {storeSupportTime || t('Support hours')}
                                                {width > 767 ? <br /> : null}
                                                {width > 767 ? (storePhone?.replace(/\s/g, '') || t('Loading phone...')) : null}
                                            </span>
                                            {(width > 767 && isPhoneHoursExpanded) && (
                                                <span className="phone-hours-header-big">
                                                    {storePhone || t('No phone available')}
                                                </span>
                                            )}
                                        </>
                                    )}

                                </div>
                            </a>
                        </button>
                    </div>
                    <div className="same-style cart-wrap account-setting real-margin" style={{ display: 'flex' }}>
                        <button
                            className="account-setting-active"
                            onClick={() => {
                                handleAccountDropdownClick();
                                setShopCartValue(false);
                            }}
                            ref={refOne}
                        >
                            <i className={`pe-7s-user-female ${accountDropDown ? 'theme-color' : ''}`} />
                        </button>

                        <div className={(!clicked || !accountDropDown) ? "account-dropdown" : "account-dropdown active"} ref={refOne2}>
                            <span className="arrowbottom"></span>
                            <ul>
                                {/* //TODO Here you have to pass the user from myAccount as well, but at the moment it works. */}
                                {fetchToken() || token ?
                                    (<>
                                        <li>
                                            <Link to={process.env.PUBLIC_URL + '/account'}>
                                                <span className="theme-color-hover"><i className="fa fa-user"></i>{'\u00A0'}{" "}{t('Account')}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={process.env.PUBLIC_URL + '/login-register'} onClick={signOut}>
                                                <span className="theme-color-red-hover"><i className="fa fa-power-off"></i>{'\u00A0'}{" "}{t('Log out')}</span>
                                            </Link>
                                        </li>
                                    </>)
                                    :
                                    (<li>
                                        <Link to={process.env.PUBLIC_URL + "/login-register"}>
                                            <span className="theme-color-hover"><i className="fa fa-sign-in"></i>{'\u00A0'}{" "}{t('Login')}/{t('Register')}</span>
                                        </Link>
                                    </li>)
                                }
                            </ul>
                        </div>
                    </div>

                    <div className="same-style cart-wrap d-none d-lg-flex">
                        <button className="icon-cart"
                            onClick={() => {
                                handleCartDropdownClick();
                                setShopCartValue(!shopCartValue);
                            }}
                            ref={refZero}
                        >
                            <i className={`pe-7s-shopbag ${cartDropDown ? '' : ''}`} />
                            {cartData.map((key) => {
                                itemsInCart = itemsInCart + key.quantity
                                return (
                                    <span
                                        key={key.PRODUCT_SALE_ELEMENT}
                                        className={`count-style ${itemsInCart > 99 && 'count-style-smaller-font'}`}
                                    >
                                        {formatNumber(itemsInCart)}
                                    </span>
                                );
                            })}
                        </button>
                        <MenuCart
                            cartData={cartData}
                            currency={currency}
                            deleteFromCart={deleteFromCart}
                            refOfIcon={refZero}
                        />
                    </div>
                    <div className="same-style cart-wrap d-flex d-lg-none real-margin real-margin-mr15" style={{ alignItems: 'center' }}>
                        <Link className="icon-cart" to={`${process.env.PUBLIC_URL}/cart`}>
                            <i className="pe-7s-shopbag" />
                            <span className={`count-style ${itemsInCart > 99 && 'count-style-smaller-font'}`}>
                                {formatNumber(itemsInCart)}
                            </span>
                        </Link>
                    </div>
                </div>
                <div className="profile_name">{profileNameRTK?.toLowerCase()}</div>
            </div>
            <div className="same-style cart-wrap mobile-off-canvas d-block d-xl-none top-mob-menu">
                <button
                    className="mobile-aside-button"
                    onClick={() => triggerMobileMenu()}
                >
                    <i className="pe-7s-menu" />
                </button>
            </div>
        </div>
    );
}

IconGroup.propTypes = {
    cartData: PropTypes.array,
    compareData: PropTypes.array,
    currency: PropTypes.object,
    iconWhiteClass: PropTypes.string,
    deleteFromCart: PropTypes.func,
    wishlistData: PropTypes.array,
};

const mapStateToProps = state => ({
    currency: state.currencyData,
    cartData: state.cartData,
    wishlistData: state.wishlistData,
    compareData: state.compareData,
});

const mapDispatchToProps = dispatch => ({
    deleteFromCart: (item, addToast, translatedMessage) => {
        dispatch(deleteFromCart(item, addToast, translatedMessage));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(IconGroup);
