import React from 'react';
import { useLocalStorageState } from 'ahooks';
import { useGetModuleQuery } from '../../redux/service/fastApiService';
import styles from './SalesBarComponent.module.css';
import localStorageConstants from '../../config/localStorageConstants';

const { salesBarVisible } = localStorageConstants;
const SalesBarComponent = () => {
    const [isVisible, setIsVisible] = useLocalStorageState(salesBarVisible, { defaultValue: true, listenStorageChange: true });
    const { data, isSuccess } = useGetModuleQuery({ module_name: 'SalesBar' }, { skip: !isVisible });

    if (!isSuccess || !isVisible) {
        return null;
    }

    const { value, module_config } = data;
    const { module } = module_config;

    if (module?.activate === 0) {
        return null;
    }

    const parsedValue = JSON.parse(value);
    const salesbar_link = parsedValue?.salesbar_link;
    const salesbar_link_text = parsedValue?.salesbar_link_text;
    const { salesbar_message, salesbar_dismiss_button, salesbar_active } = parsedValue;

    return (
        isSuccess && salesbar_active && (<div className={styles.container}>
            <div className={styles.message}>{salesbar_message} {" "}
                {salesbar_link && salesbar_link_text && <a href={salesbar_link}>{salesbar_link_text}</a>}
            </div>
            <button className={styles.dismissButton} onClick={() => setIsVisible(false)}>
                {salesbar_dismiss_button}
            </button>
        </div>)

    );
};

export default SalesBarComponent;
